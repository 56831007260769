import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLead(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('lead', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchJunkLeads(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('junkLeadList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    getLeadById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`lead/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveLead`, leadData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listSection(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('listSection', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSection(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSection`, leadData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveLeadComment(ctx, leadCommentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveLeadComment`, leadCommentData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listLeadComments(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`listLeadComments/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    leadSaleQuoteCreate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`leadSaleQuoteCreate`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    leadPurchaseQuoteCreate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`leadPurchaseQuoteCreate`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSection(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSection`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`lead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateLeadIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateLeadIsCompleted`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    junkLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`junkLead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    lostLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`lostLead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    winLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`winLead/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    interactionLog(ctx, interactionLog) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`interactionLog`, interactionLog)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateAssignLead(ctx, leadData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`assignUpdate/${leadData.id}`, leadData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryLeadDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAllSale(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('allSaleQuotes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAllPurchase(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('allPurchaseQuotes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('leadUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadLead', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveLeadChannel(ctx, leadChannel) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveLeadChannel`, leadChannel)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchLeads(ctx, payload) {
      // console.log(payload);
      return new Promise((resolve, reject) => {
        axiosIns
          .get('leadsList', { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateOrderNumbers(ctx, payload) {
      // console.log(payload);
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateOrderNumbers', { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/apps/todo/tasks', { task: taskData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/apps/todo/tasks/${task.id}`, { task })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/apps/todo/tasks/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
